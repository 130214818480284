import React from 'react'
import AppSeo, { imageUrlBuilder } from '../../../../modules/AppSeo/AppSeo'
import FrontHeaderV2 from '../../../Shared/FrontHeader/FrontHeaderV2'
import FrontFooter from '../../../Shared/FrontFooter/FrontFooter'
import Slider from "react-slick";
import Accordion from 'react-bootstrap/Accordion';

const Garage = () => {
    var settings = {
        dots: true,
        infinite: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    return (
        <div className="page app-component-container">
            <AppSeo 
                title="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h"
                author="Sadio Sangharé" 
                content="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h depuis 2010 ✔️Ouverture de porte 90€ ✔️Urgence 7j/7 ✔️Devis Gratuit ✔️ Agréé Assurance ⭐ 4.7/5 ☆ sur Google" 
                image="/images/appImages/seo-image.png" 
            />


            <FrontHeaderV2 />
            <div className="page-content-container">
                <section id="page-cover" className="page-cover-container garage">
                    <div className="homepage-cover-resume-container">
                        <h1 className="oh-page-cover-title">Porte de garage</h1>
                        <h2 className="oh-page-title-resume">Réparation porte de garage, Dépannage, installation, motorisation</h2>
                    </div>
                    <div className="page-background-overlay"></div>
                </section>

                <section className="container oh-app-page-container">
                    <div className="service-type-content-body">
                        <div className="row service-type-row">
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/depannage-porte-garage.svg" alt="réparateur porte de garage bloquée montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Réparation porte de garage</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Remplacement de galet</li>
                                                <li>Changement ressort compensation</li>
                                                <li>Remplacement serrure et cylindre</li>
                                                <li>Remplacement câble de manœuvre</li>
                                                <li>Réparation suite effraction</li>
                                                <li>Réglage et graissage</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/remplacement-serrure-porte-garage.svg" alt="remplacement serrure sur porte de garage montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Installation porte de garage</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Remplacement totale porte</li>
                                                <li>Installation porte sectionnelle</li>
                                                <li>Installation porte latérale</li>
                                                <li>Installation porte enroulable</li>
                                                <li>Installation porte basculante</li>
                                                <li>Installation porte battante</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/reparation-porte-garage.svg" alt="reparateur porte de garage montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Motorisation porte de garage</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Motorisation toutes marques</li>
                                                <li>Raccordement électrique</li>
                                                <li>Réglage du moteur</li>
                                                <li>Motorisation déportée</li>
                                                <li>Motorisation à bras articulé</li>
                                                <li>Motorisation intégrée</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/12/reparation-volet-roulant.svg" alt="réparation volet roulant montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Vous avez une autre demande ?</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Devis gratuit et express !</li>
                                            </ul>
                                        </div>
                                        <a href="tel:06 44 96 24 01" target="_blank" className="call-me-btn btn">06 44 96 24 01</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-textual-container">
                        <div className="service-textual-content-body">
                            <div className="row promo-row">
                                <div className="col-lg-3 promo-image-col">
                                    <div className="promo-image-container">
                                        <img src="https://accord-assistance.fr/wp-content/uploads/2021/08/securisation-domicile-accord-assistance.jpg" alt="Serruriers de Montpellier pas cher" className="promo-image" />
                                    </div>
                                </div>
                                <div className="col-lg-9 promo-text-col">
                                    <div className="promo-text-container">
                                        <h4 className="promo-text-title">Besoin d'un dépannage pour votre porte de garage ?</h4>
                                        <h5 className="promo-text-second-title">Besoin d'un dépannage pour votre porte de garage ?</h5>
                                        <div className="promo-text-content-body">
                                            <p>Problème d’ouverture, tentative d’effraction, clés perdues ou cassées dans le barillet ? Nos experts interviennent rapidement pour vous dépanner tous les jours de la semaine 24h/24. Les Serruriers de Montpellier vous propose également la motorisation pour gagner en praticité et sécurité.</p>
                                        </div>
                                        <div className="service-promo-with-slide-slider-container">
                                            <div className="reactif-slide-container"> 
                                                <Slider {...settings}>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-agree-assurance.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier agréé par les assurances" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Tarifs compétitifs agréés assurances</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                        Notre grille tarifaire est validée par de nombreuses compagnies d’assurances. Tous nos tarifs incluent le déplacement, la main d’œuvre et les fournitures.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-urgence.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier agréé par les assurances" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Contact client permanent</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                        Nous sommes là pour vous assister par téléphone de la prise de rendez-vous jusqu’à la réalisation des travaux, afin de répondre à toutes vos questions.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-devis-gratuit.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier devis gratuit sans engagement" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Devis gratuit</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                    Nous intervenons chez vous pour établir un devis gratuit sans engagement en tenant compte des spécificités de votre domicile et de votre budget.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-intervention.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier intervention 7j/7 24h/24" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Disponible 7j/7 24h/24</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                    Notre équipe de serruriers menuisiers est disponible tous les jours de la semaine, y compris les jours fériés, 24h/24.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slider> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-volet-type-container">
                        <div className="service-volet-type-content-body">
                            <div className="service-volet-question-container">
                                <div className="service-volet-question-row">
                                    <h4 className="servicevolet-question-title">Quelle porte de garage choisir ?</h4>
                                    <div className="service-volet-question-text-container">
                                        <p>La porte de garage dans une maison est le second accès pour un cambrioleur après la porte d’entrée. En cas de porte d’entrée résistante, les malfaiteurs vont s’attaquer à votre porte de garage qui souvent est mal sécurisée. </p>
                                        <p>C’est dans cette entrée que se trouve votre véhicule mais aussi la porte communiquant directement avec votre lieu de vie. Il existe de nombreux modèles de porte de garage, et nos professionnels sauront vous assister pour trouver la solution la plus adaptée en prenant en compte la configuration du garage et celle de votre lieu de vie.</p>
                                    </div>
                                </div>

                                <div className="service-volet-question-row mt-4">
                                    <h4 className="servicevolet-question-title">Sur quelles pannes de porte de garage intervenons-nous ?</h4>
                                </div>
                                <div className="row service-volet-response-row">
                                    <div className="service-volet-response-col col-lg-9">
                                        <div className="service-volet-response-content-body">
                                            <h5 className="service-volet-response-title">Problèmes mécaniques sur porte de garage</h5>
                                            <div className="service-volet-response-listing-container">
                                                <ul className="service-volet-response-listing">
                                                    <li>Défaut d’alignement des rails : Pour assurer un mouvement fluide des panneaux du tablier, il est essentiel que les rails soient parfaitement alignés.</li>
                                                    <li>Déraillement des roulettes : Des chocs répétés ou des manipulations incorrectes peuvent entraîner le déraillement d’une ou plusieurs roulettes des rails.</li>
                                                    <li>Perte de tension du ressort de traction : Lorsque le ressort de traction perd sa tension, il est nécessaire de réajuster sa tension initiale ou de le remplacer par un nouveau.</li>
                                                    <li>Cassure du câble : L’usure due au temps est souvent la cause principale de la rupture de ces câbles. Dans ce cas, il est recommandé de les remplacer, ainsi que toute la quincaillerie associée (poulies, ressorts, etc.).</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-volet-response-image-col col-lg-3">
                                        <div className="service-volet-image-content-body">
                                            <img src="https://accord-assistance.fr/wp-content/uploads/2024/04/probleme-mecanique-porte-garage.webp" alt="réparation de porte garage à montpellier" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row service-volet-response-row">
                                    <div className="service-volet-response-col col-lg-9">
                                        <div className="service-volet-response-content-body">
                                            <h5 className="service-volet-response-title">Problèmes électriques sur porte de garage</h5>
                                            <div className="service-volet-response-listing-container">
                                                <ul className="service-volet-response-listing">
                                                    <li>Panne électrique soudaine peut entraîner le blocage de votre porte de garage</li>
                                                    <li>Réglages incorrects des fins de course sur le moteur électrique</li>
                                                    <li>Porte de garage automatique qui s’ouvre lentement</li>
                                                    <li>Endommagement de la carte électronique de la porte de garage motorisée</li>
                                                    <li>Moteur de porte de garage ne fonctionne plus ou usé</li>
                                                    <li>Cellules photoélectriques abîmées et causant un coincement partiel de la porte</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-volet-response-image-col col-lg-3">
                                        <div className="service-volet-image-content-body">
                                            <img src="https://accord-assistance.fr/wp-content/uploads/2024/04/probleme-mecanique-porte-garage.webp" alt="réparation de porte garage à montpellier" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="why-choose-container">
                        <div className="why-choose-content-body">
                            <h2 className="why-choose-title">Pourquoi faire appel aux Serruriers de Montpellier ?</h2>
                            <div className="why-choose-accordion-container">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Pourquoi choisir Les Serruriers de Montpellier ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Avec plus de 15 ans d’expérience, nous avons su nous démarquer des serruriers véreux qui sévisse dans le milieu. Nous sommes agréés assurances depuis de nombreuses années et pratiquons une grille unique et sans surprise. 
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Quel est le prix d'une ouverture de porte ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Pour une ouverture de porte claquée le tarif est de 90 euros ttc le jour, 135 euros la nuit et pas 1 euros de plus !
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Quel est le délais d'intervention  ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Nos serruriers interviennent dans la demi-heure qui suit votre appel !
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="fullpage-section" className="counter-section">
                    <div className="counter-section-container">
                        <div className="counter-section-content">
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">10+</h4>
                                <div className="counter-item-text">années d'expérience</div>
                            </div>
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">4.7/5</h4>
                                <div className="counter-item-text">sur Google Score</div>
                            </div>
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">+200</h4>
                                <div className="counter-item-text">clients satisfaits</div>
                            </div>
                        </div>
                    </div>
                </section>

                <FrontFooter />
            </div>
        </div>
    )
}

export default Garage;
