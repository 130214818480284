import React from 'react'
import AppSeo, { imageUrlBuilder } from '../../../modules/AppSeo/AppSeo'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import FrontFooter from '../../Shared/FrontFooter/FrontFooter';
import FrontHeaderV2 from '../../Shared/FrontHeader/FrontHeaderV2';

const Homepage = () => {
    var settings = {
        dots: true,
        infinite: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    var _settings = {
        dots: true,
        infinite: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    return (
        <div className="page app-component-container">
            <AppSeo 
                title="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h"
                author="Sadio Sangharé" 
                content="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h depuis 2010 ✔️Ouverture de porte 90€ ✔️Urgence 7j/7 ✔️Devis Gratuit ✔️ Agréé Assurance ⭐ 4.7/5 ☆ sur Google" 
                image="/images/appImages/seo-image.png" 
            />


            <FrontHeaderV2 />
            <div className="page-content-container">
                <section id="page-cover" className="page-cover-container">
                    {/* <div className="page-cover-content"></div> */}
                    <div className="homepage-cover-resume-container">
                        <h1 className="page-cover-title">Serruriers de Montpellier</h1>
                        <h2 className="page-title-resume">Votre artisan serrurier agréé par les assurances</h2>
                    </div>
                    <div className="page-background-overlay"></div>
                </section>
                <div className="container app-page-container">
                    <section id="apropos-section">
                        <div className="apropos-container">
                            <a href="mailto:contact@serrurier-pas-cher-montpellier.com" className="quotation-back-btn btn">Devis gratuit</a>
                            <div className="apropos-content">
                                <p>Serruriers de Montpellier depuis 2010, est spécialisée dans l'ouverture de porte claquée ou verrouillée, la réparation, l’installation et le dépannage de serrures, portes d’entrée, portes de garage, volets roulants, rideaux métalliques, et vitrages.</p>
                            </div>
                        </div>
                    </section>

                    <section id="reactif-infos-section">
                        <div className="reactif-infos-container">
                            <div className="row reactif-infos-row">
                                <div className="col-lg-6 reactif-infos-col">
                                    <div className="reactif-infos-resume-container">
                                        <h3 className="credo-title">Notre crédo : la réactivité</h3>
                                        <div className="reactif-infos-resume">
                                            <p>Au fil des années, nous avons su nous développer et nous moderniser pour vous proposer des services de qualité. Réactifs et disponibles 24h/24 7j/7, nous vous assurons un véritable suivi personnalisé.</p>
                                            <p>Un centre téléphonique est à votre écoute tous les jours de la semaine pour suivre le bon déroulement des interventions, des devis, et des réparations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 reactif-slide-col">
                                    <div className="reactif-slide-container"> 
                                        <Slider {...settings}>
                                            <div className="reactif-infos-slide-item-container">
                                                <div className="reactif-infos-slide-item-content">
                                                    <img decoding="async" 
                                                        src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-agree-assurance.svg" 
                                                        className="reactif-infos-slide-item-icon" 
                                                        alt="serrurier agréé par les assurances" 
                                                    />
                                                    <div className="reactif-infos-slide-item-textual-container">
                                                        <h4 className="reactif-infos-slide-item-title mb-2">Tarifs compétitifs agréés assurances</h4>
                                                        <div className="reactif-infos-slide-item-text">
                                                            <p>
                                                                Notre grille tarifaire est validée par de nombreuses compagnies d’assurances. Tous nos tarifs incluent le déplacement, la main d’œuvre et les fournitures.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="reactif-infos-slide-item-container">
                                                <div className="reactif-infos-slide-item-content">
                                                    <img decoding="async" 
                                                        src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-urgence.svg" 
                                                        className="reactif-infos-slide-item-icon" 
                                                        alt="serrurier agréé par les assurances" 
                                                    />
                                                    <div className="reactif-infos-slide-item-textual-container">
                                                        <h4 className="reactif-infos-slide-item-title mb-2">Contact client permanent</h4>
                                                        <div className="reactif-infos-slide-item-text">
                                                            <p>
                                                                Nous sommes là pour vous assister par téléphone de la prise de rendez-vous jusqu’à la réalisation des travaux, afin de répondre à toutes vos questions.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="reactif-infos-slide-item-container">
                                                <div className="reactif-infos-slide-item-content">
                                                    <img decoding="async" 
                                                        src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-devis-gratuit.svg" 
                                                        className="reactif-infos-slide-item-icon" 
                                                        alt="serrurier devis gratuit sans engagement" 
                                                    />
                                                    <div className="reactif-infos-slide-item-textual-container">
                                                        <h4 className="reactif-infos-slide-item-title mb-2">Devis gratuit</h4>
                                                        <div className="reactif-infos-slide-item-text">
                                                            <p>
                                                            Nous intervenons chez vous pour établir un devis gratuit sans engagement en tenant compte des spécificités de votre domicile et de votre budget.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="reactif-infos-slide-item-container">
                                                <div className="reactif-infos-slide-item-content">
                                                    <img decoding="async" 
                                                        src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-intervention.svg" 
                                                        className="reactif-infos-slide-item-icon" 
                                                        alt="serrurier intervention 7j/7 24h/24" 
                                                    />
                                                    <div className="reactif-infos-slide-item-textual-container">
                                                        <h4 className="reactif-infos-slide-item-title mb-2">Disponible 7j/7 24h/24</h4>
                                                        <div className="reactif-infos-slide-item-text">
                                                            <p>
                                                            Notre équipe de serruriers menuisiers est disponible tous les jours de la semaine, y compris les jours fériés, 24h/24.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section id="fullpage-section" className="counter-section">
                    <div className="counter-section-container">
                        <div className="counter-section-content">
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">10+</h4>
                                <div className="counter-item-text">années d'expérience</div>
                            </div>
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">4.7/5</h4>
                                <div className="counter-item-text">sur Google Score</div>
                            </div>
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">+200</h4>
                                <div className="counter-item-text">clients satisfaits</div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container app-page-container">
                    <section id="services-section">
                        <div className="services-section-container">
                            <div className="row services-section-row">
                                <div className="col-lg-4 services-section-col">
                                    <div className="service-card-container serrurerie">
                                        <h3 className="service-title">Serrurerie</h3>
                                        <div className="service-text-container">
                                            <p>Ouverture de porte claquée, verrouillée, remplacement de serrure</p>
                                        </div>
                                        <Link to="/services/serrurier" className="btn goto-page-link">Voir +</Link>
                                        <div className="service-card-overload"></div>
                                    </div>
                                </div>
                                <div className="col-lg-4 services-section-col">
                                    <div className="service-card-container porte-blindee">
                                        <h3 className="service-title">Porte blindée</h3>
                                        <div className="service-text-container">
                                            <p>Installation de porte blindée A2P personnalisable certifiée CNPP</p>
                                        </div>
                                        <Link to="/services/porte-blindee" className="btn goto-page-link">Voir +</Link>
                                        <div className="service-card-overload"></div>
                                    </div>
                                </div>
                                <div className="col-lg-4 services-section-col">
                                    <div className="service-card-container porte-garage">
                                        <h3 className="service-title">Porte garage</h3>
                                        <div className="service-text-container">
                                            <p>Installation, remplacement, réparation de porte de garage</p>
                                        </div>
                                        <Link to="/services/porte-de-garage" className="btn goto-page-link">Voir +</Link>
                                        <div className="service-card-overload"></div>
                                    </div>
                                </div>
                                <div className="col-lg-12 services-section-col">
                                    <div className="service-card-container autres-services">
                                        <h3 className="service-title">Volet roulant</h3>
                                        <div className="service-text-container">
                                            <p>Réparation, Déblocage, Motorisation et Installation de volet roulant</p>
                                        </div>
                                        <div className="row autres-services-row">
                                            <div className="col-lg-4 offset-lg-4 autres-services-col">
                                                <Link to="/services/depannage-volet-roulant" className="btn goto-page-link">Voir +</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section id="fullpage-section" className="partners-section">
                    <div className="partners-section-container">
                        <div className="partners-section-content">
                            <Slider {..._settings}>
                                <div className="partners-slide-item-container" style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src="https://accord-assistance.fr/wp-content/uploads/elementor/thumbs/porte-de-garage-hormann-pcexwxuygcmyb7grveg3idlzo0xz1cakinug16ismo.png" alt="horman" className="partner-item" />
                                </div>
                                <div className="partners-slide-item-container" style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src="https://accord-assistance.fr/wp-content/uploads/elementor/thumbs/reproduction-cle-vachette-pcexwyssn6o8mtfepwuq2vdg9etc91eaushxighegg.png" alt="horman" className="partner-item" />
                                </div>
                                <div className="partners-slide-item-container" style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src="https://accord-assistance.fr/wp-content/uploads/elementor/thumbs/serrure-picard-pcexwzqmu0piyfe1kf9cnd4wusopgqi16x5ezqg0a8.png" alt="horman" className="partner-item" />
                                </div>
                                <div className="partners-slide-item-container" style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src="https://accord-assistance.fr/wp-content/uploads/elementor/thumbs/cle-abus-pcexwzqmu0piyfe1kf9cnd4wusopgqi16x5ezqg0a8.png" alt="horman" className="partner-item" />
                                </div>
                                <div className="partners-slide-item-container" style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src="https://accord-assistance.fr/wp-content/uploads/elementor/thumbs/cle-abus-pcexwzqmu0piyfe1kf9cnd4wusopgqi16x5ezqg0a8.png" alt="horman" className="partner-item" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>

                <FrontFooter />
            </div>
        </div>
    )
}

export default Homepage