import React from 'react'
import AppSeo, { imageUrlBuilder } from '../../../../modules/AppSeo/AppSeo'
import FrontHeaderV2 from '../../../Shared/FrontHeader/FrontHeaderV2'
import FrontFooter from '../../../Shared/FrontFooter/FrontFooter'
import Slider from "react-slick";
import Accordion from 'react-bootstrap/Accordion';

const Serrurier = () => {
    var settings = {
        dots: true,
        infinite: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    return (
        <div className="page app-component-container">
            <AppSeo 
                title="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h"
                author="Sadio Sangharé" 
                content="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h depuis 2010 ✔️Ouverture de porte 90€ ✔️Urgence 7j/7 ✔️Devis Gratuit ✔️ Agréé Assurance ⭐ 4.7/5 ☆ sur Google" 
                image="/images/appImages/seo-image.png" 
            />


            <FrontHeaderV2 />
            <div className="page-content-container">
                <section id="page-cover" className="page-cover-container">
                    <div className="homepage-cover-resume-container">
                        <h1 className="oh-page-cover-title">Serrurier agréé par les assurances à Montpellier</h1>
                        <h2 className="oh-page-title-resume">Ouverture de porte et remplacement de serrure</h2>
                    </div>
                    <div className="page-background-overlay"></div>
                </section>

                <section className="container oh-app-page-container">
                    <div className="service-price-table-container">
                        <div className="service-price-table-content-body">
                            <div className="service-price-card">
                                <img src="https://accord-assistance.fr/wp-content/uploads/2022/04/moon.png" alt="Ouverture porte claquée jour, Serrurier agréé assurance à montpellier" className="service-price-card-icon-image" />
                                <h3 className="service-card-title">Ouverture porte claquée jour</h3>
                                <div className="service-card-price-container">
                                    <div className="service-card-price">90€</div>
                                </div>
                            </div>
                            <div className="service-price-card">
                                <img src="https://accord-assistance.fr/wp-content/uploads/2022/04/moon.png" alt="Ouverture porte claquée nuit, Serrurier agréé assurance à montpellier" className="service-price-card-icon-image" />
                                <h3 className="service-card-title">Ouverture porte claquée nuit</h3>
                                <div className="service-card-price-container">
                                    <div className="service-card-price">135€</div>
                                </div>
                            </div>
                            <div className="service-price-card">
                                <img src="https://accord-assistance.fr/wp-content/uploads/2022/04/moon.png" alt="Ouverture porte verrouillée jour, Serrurier agréé assurance à montpellier" className="service-price-card-icon-image" />
                                <h3 className="service-card-title">Ouverture porte verrouillée jour</h3>
                                <div className="service-card-price-container">
                                    <div className="service-card-price">90€</div>
                                </div>
                            </div>
                            <div className="service-price-card">
                                <img src="https://accord-assistance.fr/wp-content/uploads/2022/04/moon.png" alt="Ouverture porte verrouillée nuit, Serrurier agréé assurance à montpellier" className="service-price-card-icon-image" />
                                <h3 className="service-card-title">Ouverture porte verrouillée nuit</h3>
                                <div className="service-card-price-container">
                                    <div className="service-card-price">135€</div>
                                </div>
                            </div>
                            <div className="service-price-card">
                                <img src="https://accord-assistance.fr/wp-content/uploads/2022/04/moon.png" alt="Changement serrure jour, Serrurier agréé assurance à montpellier" className="service-price-card-icon-image" />
                                <h3 className="service-card-title">Changement serrure jour</h3>
                                <div className="service-card-price-container">
                                    <div className="service-card-price">130€</div>
                                </div>
                            </div>
                            <div className="service-price-card">
                                <img src="https://accord-assistance.fr/wp-content/uploads/2022/04/moon.png" alt="Changement serrure nuit, Serrurier agréé assurance à montpellier" className="service-price-card-icon-image" />
                                <h3 className="service-card-title">Changement serrure nuit</h3>
                                <div className="service-card-price-container">
                                    <div className="service-card-price">130€</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-textual-container">
                        <div className="service-textual-content-body">
                            <div className="row service-textual-row">
                                <div className="col-lg-6 service-textual-col img-left-col">
                                    <div className="service-textual-image-container">
                                        <img src="https://accord-assistance.fr/wp-content/uploads/2022/05/serrurier-lyon-pas-cher.jpeg" alt="Ouverture de porte et remplacement de serrure" className="service-textual-image" />
                                    </div>
                                </div>
                                <div className="col-lg-6 service-textual-col service-textual-col">
                                    <div className="service-textual-content-body">
                                        <p className="service-textual-title">Tarifs sans surprises</p>
                                        <div className="service-textual-content">
                                            <p className="service-textual-item">Interventions au prix stipulé, et pas un euro de plus. Nos tarifs sont fixes et transparents. Ils sont affichés TTC et comprennent le déplacement et la main d'œuvre. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row service-textual-row">
                                <div className="col-lg-6 service-textual-col service-textual-col">
                                    <div className="service-textual-content-body">
                                        <p className="service-textual-title">Avis vérifiés sur Google</p>
                                        <div className="service-textual-content">
                                            <p className="service-textual-item">Nos clients sont satisfaits ! Une moyenne de 4,6/5 sur Google. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 service-textual-col img-left-col">
                                    <div className="service-textual-image-container">
                                        <img src="https://accord-assistance.fr/wp-content/uploads/2022/05/18.jpg" alt="Ouverture de porte et remplacement de serrure" className="service-textual-image" />
                                    </div>
                                </div>
                            </div>
                            <div className="row promo-row">
                                <div className="col-lg-3 promo-image-col">
                                    <div className="promo-image-container">
                                        <img src="https://accord-assistance.fr/wp-content/uploads/2021/09/shutterstock_2038327535-1.jpg" alt="Serruriers de Montpellier pas cher" className="promo-image" />
                                    </div>
                                </div>
                                <div className="col-lg-9 promo-text-col">
                                    <div className="promo-text-container">
                                        <h4 className="promo-text-title">Besoin d'un serrurier agréé assurance ?</h4>
                                        <h5 className="promo-text-second-title">Intervention en urgence 24h/24, 7j/7</h5>
                                        <div className="promo-text-content-body">
                                            <p>En choisissant Les Serruriers de Montpellier, pas de mauvaises surprises sur les tarifs ! Fini les "à partir de.." , ou bien encore l'obligation de changer la serrure alors que cela n’est pas nécessaire, notamment dans le cas d’une ouverture de porte claquée.</p>
                                            <p>Notre objectif ? Satisfaire nos clients en leur offrant un service de qualité, toujours accompagné d'un prix fixe et connu à l'avance !</p>
                                            <p>Notre but ? Nous démarquer des serruriers véreux qui sévissent dans le monde de la serrurerie et qui maîtrisent l'art de tromper les consommateurs lors de réparations d'urgence avec des tarifs exorbitants.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-promo-with-slide-container">
                        <div className="service-promo-with-slide-content-body">
                            <h2 className="service-promo-with-slide-title">Dépanneur serrurier Montpellier</h2>
                            <div className="service-promo-with-slide-intro-container">
                                <p>Les Serruriers de Montpellier met à votre disposition ses services pour l‘ouverture et la fermeture de porte, mais aussi le remplacement de serrure ou la réparation de porte 24h/24, 7j/7 même les jours fériés.</p>
                                <p><strong>Besoin d’une ouverture de porte rapide ? Une mise en sécurité après effraction ? La pose d’une porte blindée ? Les Serruriers de Montpellier sont là pour vous !</strong></p>
                            </div>
                            <div className="service-promo-with-slide-slider-container">
                                <div className="reactif-slide-container"> 
                                    <Slider {...settings}>
                                        <div className="reactif-infos-slide-item-container">
                                            <div className="reactif-infos-slide-item-content">
                                                <img decoding="async" 
                                                    src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-agree-assurance.svg" 
                                                    className="reactif-infos-slide-item-icon" 
                                                    alt="serrurier agréé par les assurances" 
                                                />
                                                <div className="reactif-infos-slide-item-textual-container">
                                                    <h4 className="reactif-infos-slide-item-title mb-2">Tarifs compétitifs agréés assurances</h4>
                                                    <div className="reactif-infos-slide-item-text">
                                                        <p>
                                                            Notre grille tarifaire est validée par de nombreuses compagnies d’assurances. Tous nos tarifs incluent le déplacement, la main d’œuvre et les fournitures.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="reactif-infos-slide-item-container">
                                            <div className="reactif-infos-slide-item-content">
                                                <img decoding="async" 
                                                    src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-urgence.svg" 
                                                    className="reactif-infos-slide-item-icon" 
                                                    alt="serrurier agréé par les assurances" 
                                                />
                                                <div className="reactif-infos-slide-item-textual-container">
                                                    <h4 className="reactif-infos-slide-item-title mb-2">Contact client permanent</h4>
                                                    <div className="reactif-infos-slide-item-text">
                                                        <p>
                                                            Nous sommes là pour vous assister par téléphone de la prise de rendez-vous jusqu’à la réalisation des travaux, afin de répondre à toutes vos questions.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="reactif-infos-slide-item-container">
                                            <div className="reactif-infos-slide-item-content">
                                                <img decoding="async" 
                                                    src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-devis-gratuit.svg" 
                                                    className="reactif-infos-slide-item-icon" 
                                                    alt="serrurier devis gratuit sans engagement" 
                                                />
                                                <div className="reactif-infos-slide-item-textual-container">
                                                    <h4 className="reactif-infos-slide-item-title mb-2">Devis gratuit</h4>
                                                    <div className="reactif-infos-slide-item-text">
                                                        <p>
                                                        Nous intervenons chez vous pour établir un devis gratuit sans engagement en tenant compte des spécificités de votre domicile et de votre budget.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="reactif-infos-slide-item-container">
                                            <div className="reactif-infos-slide-item-content">
                                                <img decoding="async" 
                                                    src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-intervention.svg" 
                                                    className="reactif-infos-slide-item-icon" 
                                                    alt="serrurier intervention 7j/7 24h/24" 
                                                />
                                                <div className="reactif-infos-slide-item-textual-container">
                                                    <h4 className="reactif-infos-slide-item-title mb-2">Disponible 7j/7 24h/24</h4>
                                                    <div className="reactif-infos-slide-item-text">
                                                        <p>
                                                        Notre équipe de serruriers menuisiers est disponible tous les jours de la semaine, y compris les jours fériés, 24h/24.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-details-container">
                        <div className="service-details-content-body">
                            <div className="row service-details-row">
                                <div className="col-lg-4 service-details-col">
                                    <div className="service-details-card-container">
                                        <h2 className="service-details-card-title">Ouverture de porte</h2>
                                        <div className="service-details-icon-container">
                                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" height="512" viewBox="0 0 128 128" width="512" data-name="Layer 1"><path d="m113.206 106.265h-13.623v-86.28a1.749 1.749 0 0 0 -1.554-1.738l-46-5.192a1.75 1.75 0 0 0 -1.946 1.739v3.441h-19.916a1.75 1.75 0 0 0 -1.75 1.75v86.28h-13.623a1.75 1.75 0 1 0 0 3.5h35.289v3.441a1.75 1.75 0 0 0 1.75 1.75 1.61 1.61 0 0 0 .2-.011l45.9-5.18h15.276a1.75 1.75 0 0 0 0-3.5zm-81.289-84.53h18.166v4.5h-11.917a1.75 1.75 0 0 0 -1.75 1.75v78.28h-4.5zm8 84.53v-76.53h10.166v76.53zm13.666-89.513 42.5 4.8v84.9l-42.5 4.8z"></path><path d="m61.125 58.792a5.208 5.208 0 1 0 5.208 5.208 5.214 5.214 0 0 0 -5.208-5.208zm0 6.916a1.708 1.708 0 1 1 1.708-1.708 1.71 1.71 0 0 1 -1.708 1.708z"></path></svg>
                                        </div>
                                        <div className="service-details-listing-container">
                                            <ul className="Service-details-listing">
                                                <li>Ouverture porte simple claquée</li>
                                                <li>Ouverture porte simple fermée à clé</li>
                                                <li>Ouverture porte blindée claquée</li>
                                                <li>Ouverture porte blindée fermée à clef</li>
                                                <li>Serrure bloquée</li>
                                                <li>Serrure cassée dans le barillet</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 service-details-col">
                                    <div className="service-details-card-container">
                                        <h2 className="service-details-card-title">Remplacement de serrure</h2>
                                        <div className="service-details-icon-container">
                                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" height="512" viewBox="0 0 128 128" width="512" data-name="Layer 1"><path d="m113.206 106.265h-13.623v-86.28a1.749 1.749 0 0 0 -1.554-1.738l-46-5.192a1.75 1.75 0 0 0 -1.946 1.739v3.441h-19.916a1.75 1.75 0 0 0 -1.75 1.75v86.28h-13.623a1.75 1.75 0 1 0 0 3.5h35.289v3.441a1.75 1.75 0 0 0 1.75 1.75 1.61 1.61 0 0 0 .2-.011l45.9-5.18h15.276a1.75 1.75 0 0 0 0-3.5zm-81.289-84.53h18.166v4.5h-11.917a1.75 1.75 0 0 0 -1.75 1.75v78.28h-4.5zm8 84.53v-76.53h10.166v76.53zm13.666-89.513 42.5 4.8v84.9l-42.5 4.8z"></path><path d="m61.125 58.792a5.208 5.208 0 1 0 5.208 5.208 5.214 5.214 0 0 0 -5.208-5.208zm0 6.916a1.708 1.708 0 1 1 1.708-1.708 1.71 1.71 0 0 1 -1.708 1.708z"></path></svg>
                                        </div>
                                        <div className="service-details-listing-container">
                                            <ul className="Service-details-listing">
                                                <li>Pose de serrures 3 points à 7 points</li>
                                                <li>Pose de serrure à encastrer</li>
                                                <li>Pose de serrure en applique</li>
                                                <li>Remplacement serrure et cylindre</li>
                                                <li>Verrou simple</li>
                                                <li>Verrou haute sécurité</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 service-details-col">
                                    <div className="service-details-card-container">
                                        <h2 className="service-details-card-title">Sécurisation de porte</h2>
                                        <div className="service-details-icon-container">
                                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" height="512" viewBox="0 0 128 128" width="512" data-name="Layer 1"><path d="m113.206 106.265h-13.623v-86.28a1.749 1.749 0 0 0 -1.554-1.738l-46-5.192a1.75 1.75 0 0 0 -1.946 1.739v3.441h-19.916a1.75 1.75 0 0 0 -1.75 1.75v86.28h-13.623a1.75 1.75 0 1 0 0 3.5h35.289v3.441a1.75 1.75 0 0 0 1.75 1.75 1.61 1.61 0 0 0 .2-.011l45.9-5.18h15.276a1.75 1.75 0 0 0 0-3.5zm-81.289-84.53h18.166v4.5h-11.917a1.75 1.75 0 0 0 -1.75 1.75v78.28h-4.5zm8 84.53v-76.53h10.166v76.53zm13.666-89.513 42.5 4.8v84.9l-42.5 4.8z"></path><path d="m61.125 58.792a5.208 5.208 0 1 0 5.208 5.208 5.214 5.214 0 0 0 -5.208-5.208zm0 6.916a1.708 1.708 0 1 1 1.708-1.708 1.71 1.71 0 0 1 -1.708 1.708z"></path></svg>
                                        </div>
                                        <div className="service-details-listing-container">
                                            <ul className="Service-details-listing">
                                                <li>Porte blindée / blindage de porte</li>
                                                <li>Poignée blindée</li>
                                                <li>Cornière anti-pince</li>
                                                <li>Protège-cylindre magnétique</li>
                                                <li>Protection anti-dégondage</li>
                                                <li>Paumelles pour renforcer les gonds</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="why-choose-container">
                        <div className="why-choose-content-body">
                            <h2 className="why-choose-title">Pourquoi faire appel aux Serruriers de Montpellier ?</h2>
                            <div className="why-choose-accordion-container">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Pourquoi choisir Les Serruriers de Montpellier ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Avec plus de 15 ans d’expérience, nous avons su nous démarquer des serruriers véreux qui sévisse dans le milieu. Nous sommes agréés assurances depuis de nombreuses années et pratiquons une grille unique et sans surprise. 
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Quel est le prix d'une ouverture de porte ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Pour une ouverture de porte claquée le tarif est de 90 euros ttc le jour, 135 euros la nuit et pas 1 euros de plus !
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Quel est le délais d'intervention  ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Nos serruriers interviennent dans la demi-heure qui suit votre appel !
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>

                <FrontFooter />
            </div>
        </div>
    )
}

export default Serrurier
